
.loader img{
width: 30%;
}

@media (max-width:550px) {
  .loader img{
    width: 80%;
    padding-top: 40%;
    }
}